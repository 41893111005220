import { createTheme as createMuiTheme } from "@mui/material/styles";
import breakpoints from "./breakpoints";
import overrides from "./overrides";
import props from "./props";
import typography from "./typography";
import variants from "./variants";

const createTheme = (name) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme({
    components: {
      typography: {
        fontFamily: "'Geist', 'Roboto', 'Arial', sans-serif",
        h1: {
          fontFamily: "'Geist', sans-serif",
          fontWeight: 600,
        },
        body1: {
          fontFamily: "'Geist', sans-serif",
        },
      },
      MuiTextField: {
        defaultProps: {
          fontFamily: "'Geist', 'Roboto', 'Arial', sans-serif",
          variant: 'standard',
          size: "small"
        },
        MuiLink: {
          defaultProps: {
            underline: 'hover',
          },
        }
      },
      MuiCard: {
        defaultProps: {
          variant: "outlined", // Define a variant padrão como 'outlined'
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            // Remove a sombra padrão
            boxShadow: "none",
          },
        },
      },
      MuiInputLabel: {
        defaultProps: {
          size: "small"
        },
      },
      MuiSelect: {
        defaultProps: {
          size: "small"
        },
      }
    },
    spacing: 4,
    breakpoints: breakpoints,
    overrides: overrides,
    props: props,
    typography: typography,

    // shadows: shadows,
    palette: themeConfig.palette,
  }, {
    name: themeConfig.name,
    header: themeConfig.header,
    footer: themeConfig.footer,
    sidebar: themeConfig.sidebar,
  });
};

export default createTheme;
