import { FormControl, Grid, InputLabel, Select } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Help from "../popover/Help";

const SelectMenu = (props) => {
  return (
    <Grid container spacing={6}>
      {props.legenda && (
        <Grid item xs={props.legenda ? 1 : 0}>
          {props.legenda && (
            <Grid item>
              <Help description={props.legenda} />
            </Grid>
          )}
        </Grid>
      )}
      <Grid item xs={!props.legenda ? 12 : 11}>
        <FormControl fullWidth={props.fullWidth} variant="outlined">
          <InputLabel
            required={props.required}
            id={`${props.id}_${props.label}`}
          >
            {props.label}
          </InputLabel>
          <Select required labelId={`${props.id}_${props.label}`} multiple={props.multiple} {...props}>
            {props.children}
          </Select>
        </FormControl>
      </Grid> 
    </Grid>
  );
};

SelectMenu.propTypes = {
  fullWidth: PropTypes.bool,
  id: PropTypes.string.isRequired,
  required: PropTypes.bool,
  multiple: PropTypes.bool,
};

SelectMenu.defaultProps = {
  fullWidth: true,
  required: false,
  multiple: false,
};

export default SelectMenu;
